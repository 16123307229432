.sidebar {
/*    display: flex;
    flex-direction: column;*/
    position: relative;
    width: 250px!important;
    left: 0;
}
.openSidebar.hide {
    top:50px!important;
    left:0!important;
    border-radius: 0 30px 30px 0;
}
.openSidebar.show {
    top:50px!important;
    right:-20px!important;
    border-radius: 0 30px 30px 0;
}

.hiddenSide {
    height: 100%;
    left: -235px!important;
    position: absolute;

}
.sidebar.hoverShow {
    transition:left,1s;
    left: 0!important;
}
