.priority_1 {
    background-color: #ff4441 !important;
    padding: 4px;
    color: white;
    border-radius: 5px;
    font-weight: bold;
}

.priority_2 {
    background-color: #ff994c !important;
    padding: 4px;
    color: white;
    border-radius: 5px;
    font-weight: bold;
}

.priority_3 {
    background-color: #5da1ff !important;
    padding: 4px;
    color: white;
    border-radius: 5px;
    font-weight: bold;
}

.vertical-timeline {
    width: 100%;
    position: relative;
    padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 67px;
    height: 100%;
    width: 4px;
    background: #e9ecef;
    border-radius: .25rem;
}

.vertical-timeline-element {
    position: relative;
    margin: 0 0 1rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 .8s;
}
.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
    box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
    width: 18px;
    height: 18px;
    position: relative;
}
.badge:empty {
    display: none;
}


.badge-dot-xl::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: .25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    background: #fff;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 90px;
    font-size: .8rem;
}

.vertical-timeline-element-content .timeline-title {
    font-size: .8rem;
    text-transform: uppercase;
    margin: 0 0 .5rem;
    padding: 2px 0 0;
    font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    display: block;
    position: absolute;
    left: -90px;
    top: 0;
    padding-right: 10px;
    text-align: right;
    color: #adb5bd;
    font-size: .6619rem;
    white-space: nowrap;
}

.vertical-timeline-element-content .vertical-timeline-element-time {
    display: block;
    position: absolute;
    left: -90px;
    top: 15px;
    padding-right: 10px;
    text-align: right;
    color: #adb5bd;
    font-size: .6619rem;
    white-space: nowrap;
}

.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
}

.containerScroll {
    overflow: auto;
    max-height: 400px;
}

.nav-link.active, .row.active {
    background-color: #E94E1B;
}

.spinnerPosition {
    top:0%;
    left:0%;
    margin: auto;
    width: 50px;

}
.spinnerPosition svg {
    z-index: 999;
}
.spinnerPosition:before {
    width: 100%;
    height: 100vh;
    content: '';
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top:0;
    z-index: 1;
}