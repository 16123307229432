/* AutoLogin.css */
.auto-login-container {
    margin: auto;
    text-align: center;
    width: 100%; /* Adjust the width of the container */
    max-width: 500px;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for a card-like effect */
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.logo {
    width: 200px; /* Adjust the size of your logos */
    margin: 0 10px;
}

.auto-login-heading {
    font-size: 2.5rem;
    color: #fff;
}

.additional-info {
    font-size: 1.2rem;
    color: #ddd;
}
