@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.App {
  display: flex;
  min-height: 100vh;
}

.bg-dark a {
  color: #fff!important;
}